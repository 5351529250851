
import Paho from 'paho-mqtt';
import uuidv4 from 'uuidv4';
import amatisSitesList from './models/sites';
import moment from 'moment';

const Amatis = {
    senderObj: {},
    delay_before_alert: 630,
    tableUpdateInterval: {},
    init_mqtt: function(config) {
        console.log('INSIDE INIT');
        // Amatis.client = new Paho.MQTT.Client(config.host.param, parseInt(config.port.param),'60');
        let clientID = 'amatis-status_' + Math.floor(Math.random() * 100000000);
        Amatis.client = new Paho.Client(process.env.REACT_APP_MQTT_HOST, 443, clientID);

        // set callback handlers
        Amatis.client.onConnectionLost = this.mqtt_onConnectionLost;
        Amatis.client.onMessageArrived = this.mqtt_onMessageArrived;

        var options = {
            useSSL: true,
            userName: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
            cleanSession:true,
            // Both of these options are relating to keeping the mqtt connection alive
            // reconnect:true,
            // keepAliveInterval: 30,
            onSuccess:this.mqtt_onConnect,
            onFailure:this.mqtt_onFail
        };

        // connect the client
        Amatis.client.connect(options);
        // setTimeout(()=>{Amatis.updateTableState();}, 2000);
        clearInterval(Amatis.tableUpdateInterval);
        Amatis.tableUpdateInterval = setInterval(()=>{Amatis.updateTableState();}, 2000);

        // Sending a message periodically seemed to keep the connection alive. But may cause other issues
        // setInterval(()=>{Amatis.mqtt_sendMessage(
        //     '/trash',
        //     '',
        //     {}
        // );}, 30000);
    },
    mqtt_onConnectionLost: function (responseObject) {
        if (responseObject.errorCode !== 0) {
            console.log('MQTT: CONNECTION LOST');
            console.log(responseObject);
        }
        Amatis.AppComponent.setState({connectionStatus:'Disconnected'});
    },
    mqtt_onMessageArrived: function(message) {
        // console.log(message.destinationName);
        Amatis.messageParser(message);
    },
    messageParser: (message) => {
        let topic = message.destinationName;

        if(topic.indexOf('ambr') >= 0 || topic.indexOf('switch') >= 0){
            Amatis.ambrAndXpoeSpecificInfoObjHandler(topic, message);
        }
        else {
            Amatis.generalInfoObjHandler(topic, message);
        }

    },
    generalInfoObjHandler(topic, message){
        let obj = {};
        // console.log('general:', topic, message)

        try{
            obj = JSON.parse(message.payloadString);
            // console.log(topic, obj)

            // Main.MQTTContainer.appendMessageToList(message);
        }catch(e){
            console.log(e);
        }
        if (obj !== {}){
            Amatis.updateTopicInfo(topic, obj);
            // $('#status-readout-table').html(obj.data.replace(/\n/g,'<br>').replace(/sites\//g, '&nbsp;&nbsp;&nbsp;&nbsp;sites/'));
            // console.log(obj.data);
        }
    },
    updateTopicInfo(topic, messageObj){
    // console.log(messageObj);
        if (messageObj !== {}){
            if(messageObj.hasOwnProperty('meta')){
                if (messageObj.meta.hasOwnProperty('messages_per_minute')){
                    Amatis.AppComponent.setMessagesPerMin(messageObj.meta.messages_per_minute);
                }
            }
            let siteID = '';
            let activeClient, sender;

            for(let client in messageObj.data){
                activeClient = messageObj.data[client];
                siteID = activeClient.site_id;
                sender = client;

                Amatis.updateSenderObj(topic, sender, siteID, activeClient, 'topic_info');


            }
            // console.log(Amatis.senderObj);
            // window.localStorage.setItem('Amatis.senderObj', JSON.stringify(Amatis.senderObj));
            // $('#indiv-status-readout-table').html(`<pre>${JSON.stringify(Amatis.senderObj, undefined, 2)}</pre>`);
        }
    },
    ambrAndXpoeSpecificInfoObjHandler(topic, message){
        let messageObj = {};
        let lastPingHuman = '';
        try{
            messageObj = JSON.parse(message.payloadString);
            // Main.MQTTContainer.appendMessageToList(message);
        }catch(e){
            console.log(e);
        }

        let sender, siteID;
        const topicArr = topic.split('/');

        if(topicArr.hasOwnProperty(1)){
            siteID = topicArr[1];
        }else{
            siteID = '0000';
        }

        if (messageObj !== {}){
            if(messageObj.hasOwnProperty('meta')){
                if (messageObj.meta.hasOwnProperty('sender')){
                    sender =  messageObj.meta.sender;
                }

                if (messageObj.meta.hasOwnProperty('site_id')){
                    siteID =  messageObj.meta.site_id;
                }
                //   if (messageObj.meta.hasOwnProperty('source')){
                //     let source = messageObj.meta.source;
                //   }

                //   if (messageObj.meta.hasOwnProperty('ts')){
                //     let lastPing = messageObj.meta.ts;
                //   }

                if (messageObj.meta.hasOwnProperty('ts_h')){
                    lastPingHuman = messageObj.meta.ts_h;
                }
            }

            let tempObj = {
                tsh: lastPingHuman,
                data: messageObj.data.data
            };

            Amatis.updateSenderObj(topic, sender, siteID, tempObj, 'general_info');
        }
    },
    updateSenderObj(topic, sender, siteID, data, type){
        if(!Amatis.senderObj.hasOwnProperty(siteID)){
            Amatis.senderObj[siteID] = {};
        }

        if(!Amatis.senderObj[siteID].hasOwnProperty(sender)){
            Amatis.senderObj[siteID][sender] = {topic_info: {topics: [], last_ping:0}, general_info: {}};
        }

        Amatis.senderObj[siteID][sender][type] = data;
        // console.log(Amatis.senderObj);

        // window.localStorage.setItem('Amatis.senderObj', JSON.stringify(Amatis.senderObj));
    },
    updateTableState(){
        let rowsArray = [];
        let activeSender = '';
        let alertIconString = '';
        let statusString = '';
        let alertCount = 0;
        let clientCount = 0;
        let ambrCount = 0;
        let xpoeCount = 0;
        let Names = amatisSitesList;

        for(let site in Amatis.senderObj){
            let activeSite = Amatis.senderObj[site];

            for(let sender in activeSite){
                let topicArray = [];
                activeSender = activeSite[sender];
                alertIconString = '';
                statusString = '✅';
                clientCount++;
                // console.log(activeSender, site);
                //make a row object

                if(activeSender.topic_info.last_ping > (Amatis.delay_before_alert - 60)){
                    alertCount++;
                    alertIconString = '⚠️';
                    statusString = alertIconString;
                }

                let generalInfo = activeSender.general_info;
                let uptime = '';
                let loadString = '';
                const generalInfoIsLoaded = generalInfo.hasOwnProperty('data');
                const generalData = generalInfo.data;

                //TODO: All of this can be replaced after everyone is at 12.0 because we now explicitly report this
                if (generalInfoIsLoaded && generalData.hasOwnProperty('uptime')){
                    // console.log(generalData.uptime)
                    let uptimeString = generalData.uptime;
                    try{
                        let uptimeArr = uptimeString.split(',');
                        uptimeString = uptimeArr[0];
                        let uptimeArrForReal = uptimeString.split('up');

                        if(uptimeArrForReal[1].includes('day')){
                            // console.log(uptim)
                            uptime = `${uptimeArrForReal[1].replace(' ', '')} ${uptimeArr[1]}`;
                            loadString = `${uptimeArr[3].replace('  load average: ', '')} ${uptimeArr[4]} ${uptimeArr[5]}`;
                        } else {
                            uptime = uptimeArrForReal[1].replace(' ', '');
                            loadString = `${uptimeArr[2].replace('  load average: ', '')} ${uptimeArr[3]} ${uptimeArr[4]}`;
                        }
                    } catch (e){
                        console.log(e);
                    }
                }

                let deviceType = '';
                switch(activeSender.topic_info.source){
                    case 'contiki':
                    case 'ambr_info_monitor':
                        deviceType = 'AMBR';
                        ambrCount++;
                        break;
                    case 'xpoe_info_monitor':
                        deviceType = 'X-Poe';
                        xpoeCount++;
                        break;
                    default:
                        deviceType = activeSender.topic_info.source;
                }

                let version = '-';

                if (generalInfoIsLoaded){
                    if (generalData.hasOwnProperty('ambr_version')){
                        version = generalData.ambr_version;
                    } else if (generalData.hasOwnProperty('xpoe_version')){
                        version = generalData.xpoe_version;
                    }
                }

                let rowObj = {
                    name: Names[site] || 'Unknown',
                    site_name: (site.length <= 3) ? Names[site] || 'Unknown' : 'N/A',
                    device_type: deviceType,
                    site_id: site,
                    mac_address: sender,
                    active_topics: (activeSender.topic_info.topics) ? activeSender.topic_info.topics.length : 0,
                    last_ping: `${alertIconString} (${activeSender.topic_info.last_ping}s)`,
                    uptime: (generalInfoIsLoaded && generalData.hasOwnProperty('uptime')) ? uptime : '-',
                    load: (generalInfoIsLoaded && generalData.hasOwnProperty('uptime')) ? loadString : '-',
                    routes: (generalInfoIsLoaded && typeof generalData.routes !== 'undefined') ? `${generalData.routes} / ${generalData.neighbors}` : '-',
                    routes_w_fails: (generalInfoIsLoaded) ? generalData.routes_with_fails : '-',
                    neighbors: (generalInfoIsLoaded) ? generalData.neighbors : '-',
                    ambr_version: (generalInfoIsLoaded) ? version : '-',
                    tick: (generalInfoIsLoaded) ? generalData.tick : '-',
                    version: (generalInfoIsLoaded) ? generalData.mqtt_worker : '-',
                    pse_1_version: (generalInfoIsLoaded && generalData.pse_versions && generalData.pse_versions['1']) ? generalData.pse_versions['1'] : '-',
                    pse_2_version: (generalInfoIsLoaded && generalData.pse_versions && generalData.pse_versions['2']) ?  generalData.pse_versions['2'] : 'N/A',
                    local_mqtt : (generalInfoIsLoaded && generalData.sd_card_mounted) ? generalData.sd_card_mounted.toString() : '-',
                    serial_no : (generalInfoIsLoaded) ? generalData.serial_no : '-',
                    disk_utilization : (generalInfoIsLoaded) ? generalData.disk_utilization : '-',
                    pan_id : (generalInfoIsLoaded) ? generalData.pan_id : '-',
                    timezone : (generalInfoIsLoaded) ? generalData.timezone : '-',
                    local_mqtt_connected : (generalInfoIsLoaded  && generalData.local_mqtt_connected) ? generalData.local_mqtt_connected.toString() : '-',
                    master_ambr : (generalInfoIsLoaded) ? generalData.master_ambr : '-',
                    uptime_seconds : (generalInfoIsLoaded) ? generalData.uptime_seconds : '-',
                    channel : (generalInfoIsLoaded) ? generalData.channel : '-',
                    throttle_write : (generalInfoIsLoaded && generalData.throttle_write !== undefined) ? generalData.throttle_write.toString()  : '-',
                    sd_card_mounted : (generalInfoIsLoaded && generalData.sd_card_mounted) ? generalData.sd_card_mounted.toString() : '-',
                    mqtt_worker : (generalInfoIsLoaded) ? generalData.mqtt_worker : '-',
                    mac : (generalInfoIsLoaded) ? generalData.mac : '-',
                    local_ip : (generalInfoIsLoaded) ? generalData.local_ip : '-',
                    global_ip : (generalInfoIsLoaded) ? generalData.global_ip : '-',
                    contiki_ticks : (generalInfoIsLoaded) ? generalData.contiki_ticks : '-',
                    vpn_state : (generalInfoIsLoaded) ? generalData.vpn_state : '-',
                    tasks: [],
                    status: statusString
                };

                if(generalInfoIsLoaded){
                    const numberOfRoutes = generalData.routes;
    
                    //Check for dangerous number of routes with fails
                    if(numberOfRoutes > 2 && (rowObj.routes_w_fails/numberOfRoutes) > 0.4){
                        rowObj.routes_w_fails += ` ⚠️`;
                        alertCount++;
                    }
                }

                //Sort topics by last ping
                activeSender.topic_info.topics.sort((a,b) =>{
                    return a.last_ping - b.last_ping;
                });

                for (let topic of activeSender.topic_info.topics){
                    alertIconString = (topic.last_ping > (Amatis.delay_before_alert - 60)) ? '⚠️' : '' ;
                    let dateString = moment.unix(topic.last_ts).format('MMM D YY, h:mm:ss a');
                    let topicObj  = {
                        subject: topic.topic,
                        pt1: `(${topic.last_ping}s) ${alertIconString}`,
                        pt2: topic.last_ts,
                        pt3: dateString,
                    };
                    topicArray.push(topicObj);
                }
                rowObj.tasks = topicArray;

                rowsArray.push(rowObj);
            }
        }
        if(Amatis.AppComponent.state.connectionStatus !== 'Paused'){
            Amatis.AppComponent.setState({rows:rowsArray, alertCount, clientCount:clientCount, ambrCount:ambrCount, xpoeCount:xpoeCount});
        }
    },
    mqtt_onConnect: function(){
        Amatis.AppComponent.setState({connectionStatus:'Connected'});

        // Once a connection has been made, make a subscription and send a message
        Amatis.client.subscribe('sites/0000/info_obj');
        Amatis.client.subscribe('sites/+/ambr/+/info');
        Amatis.client.subscribe('switch/+/info');
    },
    mqtt_onFail: function(e){
        console.log('MQTT FAILED TO CONNECT');
        console.log(e);
        //TODO: Consider retrying?
    },
    mqtt_sendMessage(topicString, messageString, payload={}){
        let dataObj = {
            url: messageString,
            method: 'GET',
            data: payload
        };

        let messageObj = {
            'meta': {
                'sender': '000000000',
                'data_type': 'JSON',
                'ts': Math.floor(new Date() / 1000),
                'id': uuidv4(),
                'schema': 'mqtt_v1'
            },
            'data': dataObj,
            'errors': []
        };

        // console.log(topicString, messageObj);
        let message = new Paho.Message(JSON.stringify(messageObj));
        message.destinationName = topicString;
        Amatis.client.send(message);
        // console.log(message);
    },
};

export default Amatis;