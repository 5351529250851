import React from "react";
// import PropTypes from "prop-types";
// import Paper from "material-ui/Paper";
import Amatis from "../amatis";
import Button from 'material-ui/Button';
// import Tooltip from 'material-ui/Tooltip';

// To add or edit a button, just play with this.buttonList and they will change

class AmatisButtons extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            row: props.row,
        };

        this.buttonList = [
            {
                name: 'Open App',
                color: 'blue',
                icon: 'far fa-desktop',
                click_handler:(row)=>{
                    const vpnID = row.mac_address.replace(/:/g,'').replace("00f",'').replace("88a3cc",'');
                    window.open(
                        `https://${vpnID}.ambrs.amatiscontrols.com`,
                        '_blank'
                    )
                }
            },
            {
                name: 'Reset Ambr',
                color: 'blue',
                icon: 'far fa-retweet',
                tool_tip:'Softreboot and clear the cache',
                click_handler:(row)=>{
                    let letsDoIt = window.confirm(`Reset (Soft Reboot) AMBR?`);
                    console.log(row);
                    if(letsDoIt){
                        Amatis.mqtt_sendMessage(
                            `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
                            'ambrs/me/soft_reboot'
                        );
                    }
                }
            },
            {
                name: 'Reboot Ambr',
                color: 'red',
                icon: 'far fa-power-off',
                tool_tip: 'Hard reboot of ambr (shutdown -r NOW)',
                click_handler:(row)=>{
                    let letsDoIt = window.confirm(`Reboot (Hard Reboot) AMBR ${row.mac_address}?`);
                    console.log(row);
                    if(letsDoIt){
                        Amatis.mqtt_sendMessage(
                            `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
                            'ambrs/me/restart'
                        );
                    }
                }
            },
            {
                name: 'Pan Repair',
                color: 'blue',
                icon: 'far fa-tools',
                click_handler:(row)=>{
                    let letsDoIt = window.confirm(`Trigger Pan Repair at ${row.mac_address}?`);
                    console.log(row);
                    if(letsDoIt){
                        Amatis.mqtt_sendMessage(
                            `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
                            'ambrs/me/pan_repair'
                        );
                    }
                }
            },
            // {
            //     name: 'Swinfo Harvest',
            //     color: 'blue',
            //     icon: 'far fa-info-circle',
            //     click_handler:(row)=>{
            //         let letsDoIt = window.confirm('trigger SWinfo Harvest?');
            //         console.log(row);
            //         if(letsDoIt){
            //             Amatis.mqtt_sendMessage(
            //                 `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
            //                 'ambrs/me/channel'
            //             );
            //         }
            //     }
            // },
            // {
            //     name: 'Data Harvest',
            //     color: 'blue',
            //     icon: 'far fa-chart-bar',
            //     click_handler:(row)=>{
            //         let letsDoIt = window.confirm('Trigger Data Harvest?');
            //         console.log(row);
            //         if(letsDoIt){
            //             Amatis.mqtt_sendMessage(
            //                 `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
            //                 'ambrs/me/channel'
            //             );
            //         }
            //     }
            // },
            {
                name: 'Reboot All Nodes',
                color: 'red',
                icon: 'far fa-lightbulb-exclamation',
                click_handler:(row)=>{
                    let letsDoIt = window.confirm(`Reboot all nodes on the pan at ${row.mac_address}?`);
                    console.log(row);
                    if(letsDoIt){
                        Amatis.mqtt_sendMessage(
                            `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
                            'ambrs/me/reboot_pan'
                        );
                    }
                }
            },
            // {
            //     name: 'Reconfigure Radio',
            //     color: 'red',
            //     icon: 'far fa-broadcast-tower',
            //     click_handler:(row)=>{
            //         let letsDoIt = window.confirm('Reset (Soft Reboot) Ambr?');
            //         console.log(row);
            //         if(letsDoIt){
            //             Amatis.mqtt_sendMessage(
            //                 `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
            //                 'ambrs/me/channel'
            //             );
            //         }
            //     }
            // },
            // {
            //     name: 'Toggle VPN',
            //     color: 'blue',
            //     icon: 'far fa-phone-square',
            //     click_handler:(row)=>{
            //         let letsDoIt = window.confirm('Reset (Soft Reboot) Ambr?');
            //         console.log(row);
            //         if(letsDoIt){
            //             Amatis.mqtt_sendMessage(
            //                 `sites/${row.site_id}/ambr/api/${row.mac_address}`, 
            //                 'ambrs/me/channel'
            //             );
            //         }
            //     }
            // },
            {
                name: 'Restart Data Worker',
                color: 'red',
                icon: 'fab fa-node-js',
                click_handler:(row)=>{
                    // alert('This is not yet supported')
                    let letsDoIt = window.confirm('Restart the data worker and clear its sender cache and reload the page?');
                    console.log(row);
                    if(letsDoIt){
                        Amatis.mqtt_sendMessage(
                            `workers/data`, 
                            'clear-cache-restart'
                        );
                        setTimeout(() => {window.location.reload();}, 1000);
                    }
                }
            },
            {
                name: 'Ignore AMBR',
                color: 'red',
                icon: 'far fa-trash',
                click_handler:(row)=>{
                    Amatis.mqtt_sendMessage(
                        `workers/data`,
                        'remove-sender-from-list',
                        { mac_address:row.mac_address }
                    );
                }
            },
        ];
    };

    render() {
        // console.log(row)
        return(
            <span>{
                this.buttonList.map((button, i) => {
                    return (
                        <span key={i}>
                            {/* <Tooltip style={{margin:10}} title={button.tool_tip} placement="top">   */}
                                <Button onClick={() => {button.click_handler(this.state.row)}} key={i}>
                                    <i className={button.icon} aria-hidden="true" style={{'marginRight':'10px', 'color':button.color}}></i>
                                    {button.name}
                                </Button>
                            {/* </Tooltip> */}
                        </span>
                    );
                })  
            }</span>
        );
    }
};

export default AmatisButtons;
