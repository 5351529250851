import React from "react";
import PropTypes from "prop-types";
import Paper from "material-ui/Paper";
import Amatis from './amatis';
import AmatisButtons from './amatisComponents/amatisButtons';

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  GroupingState,
  IntegratedGrouping,
  SelectionState,
  RowDetailState,
  FilteringState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  TableSelection,
  TableRowDetail,
  TableColumnVisibility,
  ColumnChooser,
//   TableColumnResizing,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";

import BlockTop from "./block-top";

const detailColumns = [
  { name: "subject", title: "Topic" },
  { name: "pt1", title: "Age" },
  { name: "pt3", title: "Last Ping Date" },
  { name: "pt2", title: "Last Ping TS" },
  // { name: "pt3", title: "Last Message" },
  // { name: "pt4", title: "Routes W/ Fails" },
  // { name: "pt5", title: "Neighbors" }
];

const RowDetail = ({ row }) => (
  <div style={{ margin: 20 }}>
    <div>
      <h5>
        {row.name === "Total" ? "Total" : `${row.mac_address} Topics`}:
      </h5>
    </div>
    <Paper style={{borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
      <GridTable rows={row.tasks} columns={detailColumns}>
        <Table />
        <TableHeaderRow />
      </GridTable>
    </Paper>
    <Paper style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, padding:10 }}>
      <AmatisButtons row={row}/>
    </Paper>
  </div>
);

RowDetail.propTypes = {
  row: PropTypes.any.isRequired
};
const sortLastPing = (a, b) => {
  a = parseInt(a.replace(/\(/g, '').replace(/s\)/g, '').replace(/⚠️/g, ''));
  b = parseInt(b.replace(/\(/g, '').replace(/s\)/g, '').replace(/⚠️/g, ''));
  return a-b;
}
const sortRoutesWithFails = (a, b) => {
  // Remove any warning icons
  a = !isNaN(a) ? a : parseInt(a.replace(/⚠️/g, ''));
  b = !isNaN(b) ? b : parseInt(b.replace(/⚠️/g, ''));

  //Force dashes to the bottom
  if(isNaN(a)){
    return 1;
  }else if(isNaN(b)){
    return -1;
  }

  return a - b;
}
const sortUptime = (a, b) => {

     //Force anything that says 'min' to the bottom
    if(!b.toString().includes('day')
        && (!a.toString().includes('min') && b.toString().includes('min'))
    ){
        return 1;
    } else if(!a.toString().includes('day')
        && (!b.toString().includes('min') && a.toString().includes('min'))
    ){
        return - 1;
    }

    //Force 'day' to the bottom as well as dashes
    if((a.toString().includes('day') && !b.toString().includes('day'))){
        return 1;
    } else if((b.toString().includes('day') && !a.toString().includes('day'))){
        return - 1;
    }

    if((a.toString().includes('day') && b.toString().includes('day'))){
        let splitStringA = a.split('day');
        let daysA = parseFloat(splitStringA[0]);
        let hoursAndMinsA = splitStringA[1].replace('s', '').replace(' ', '');

        if(hoursAndMinsA.includes(':')){
            let hoursAndMinsAArray = hoursAndMinsA.split(':');
            a = parseInt((daysA * 24 * 60)
                + (hoursAndMinsAArray[0] * 60)
                + parseInt(hoursAndMinsAArray[1]));
        }  else if (hoursAndMinsA.includes('min')){
            let minutesA = parseFloat(hoursAndMinsA.replace('min',''));
            a = parseInt((daysA * 24 * 60) + minutesA);
        }else {
            a = daysA;
        }

        let splitStringB = b.split('day');
        let daysB = parseFloat(splitStringB[0]);
        let hoursAndMinsB = splitStringB[1].replace('s', '').replace(' ', '');

        if(hoursAndMinsB.includes(':')){
            let hoursAndMinsBArray = hoursAndMinsB.split(':');
            b = parseInt((daysB * 24 * 60)
                + (hoursAndMinsBArray[0] * 60)
                + parseInt(hoursAndMinsBArray[1]));
        } else if (hoursAndMinsB.includes('min')){
            let minutesB = parseFloat(hoursAndMinsB.replace('min',''));
            b = parseInt((daysB * 24 * 60) + minutesB);
        } else {
            b = daysB;
        }
    }

    if(a > b || ( a === '-')){
        return 1;
    } else if (b > a || ( b === '-')) {
        return -1;
    }

    return 0;
  }
class AppTableGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    Amatis.init_mqtt();
    // const { InputProps, classes, ref, ...other } = props;

    this.state = {
      columns: [
        { name: "site_name", title: "Site" },
        { name: "site_id", title: "Site ID" },
        { name: "device_type", title: "Type" },
        { name: "status", title: "Status"},
        { name: "last_ping", title: "Last Ping" },
        { name: "mac_address", title: "Mac Address"},
        { name: "active_topics", title: "Active Topics" },
        { name: "uptime", title: "Uptime" },
        { name: "load", title: "Load" },
        { name: "tick", title: "Ticks" },
        { name: "routes", title: "Routes/Neighbors" },
        { name: "routes_w_fails", title: "Routes w/ Fails" },
        { name: "neighbors", title: "Neighbors" },
        { name: "serial_no", title: "Serial Number"},
        { name: "local_mqtt", title: "Local Mqtt"},
        { name: "disk_utilization", title: "Disk Utilization"},
        { name: "timezone", title: "Timezone"},
        { name: "local_mqtt_connected", title: "Local MQTT Connected"},
        { name: "master_ambr", title: "Is Master"},
        { name: "uptime_seconds", title: "Uptime (s)"},
        { name: "pan_id", title: "Pan ID"},
        { name: "channel", title: "Channel"},
        { name: "throttle_write", title: "Throttle Write"},
        { name: "sd_card_mounted", title: "SD Card Mounted"},
        { name: "local_ip", title: "Local IP"},
        { name: "global_ip", title: "Global IP"},
        { name: "contiki_ticks", title: "Contiki Ticks"},
        { name: "vpn_state", title: "VPN State"},
        { name: "ambr_version", title: "Version" },
        { name: "version", title: "Worker Version" },
        { name: "pse_1_version", title: "PSE 1 Version" },
        { name: "pse_2_version", title: "PSE 2 Version" },
      ],
      defaultHiddenColumnNames: [
        'neighbors',
        'active_topics',
        'local_mqtt',
        'serial_no',
        'disk_utilization',
        'pan_id',
        'timezone',
        'local_mqtt_connected',
        'ambr_version',
        'master_ambr',
        'uptime_seconds',
        'channel',
        'throttle_write',
        'sd_card_mounted',
        'mqtt_worker',
        'mac',
        'local_ip',
        'global_ip',
      ],
      integratedSortingColumnExtensions: [
        { columnName: 'last_ping', compare: sortLastPing},
        { columnName: 'routes_w_fails', compare: sortRoutesWithFails},
        { columnName: 'uptime', compare: sortUptime},
      ],
      rows: [],
      sorting: [{ columnName: "status", direction: "asc" }],
    //   defaultColumnWidths: [
    //     { columnName: 'site_name', width: 400 },
    //     { columnName: 'site_id', width:  80 },
    //     { columnName: 'last_ping', width: 80 },
    //     { columnName: 'mac_address', width: 100 },
    //   ],
      currentPage: 0,
      pageSize: 25,
      pageSizes: [10, 25, 50, 100],
      grouping: [],
      selection: [],
      alertCount: '-',
      messagesPerMinute: '-',
      messagesPerMinuteMax: '-',
      messagesPerMinuteMin: '-',
      messagesPerMinuteAvg: '-',
      messageCount: 1,
      clientCount: '-',
      ambrCount: '-',
      xpoeCount: '-',
      connectionStatus: 'Connecting',
      filters:[],
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.changeGrouping = grouping => this.setState({ grouping });
    this.changeSelection = selection => this.setState({ selection });
    this.handleStatusClick = this.handleStatusClick.bind(this);
    this.changeFilters = (filters) => {this.setState({ filters })};
    this.getDefaultHiddenColumnNames = () => {
      let hidden = window.localStorage.getItem('amatis-status-columns');
      return (hidden !== null) ? JSON.parse(hidden) : this.state.defaultHiddenColumnNames;
    }
    this.hiddenColumnNamesChange = (hiddenColumnNames) => {
        //Store the new changes locally
        window.localStorage.setItem('amatis-status-columns', JSON.stringify(hiddenColumnNames));
    };
    Amatis.AppComponent = this;
  }

  handleStatusClick = () =>{
    switch(this.state.connectionStatus){
      case 'Connected':
        this.setState({connectionStatus:'Paused'});
        break;

      case 'Disconnected':
        this.setState({connectionStatus:'Connecting'});
        Amatis.init_mqtt();
        break;

      case 'Paused':
        this.setState({connectionStatus:'Connected'});
        break;

      default:
        break;
    }
    console.log(this.state.connectionStatus);
  };

  setMessagesPerMin = (rate) => {
    let tempObj = {};
    let count = this.state.messageCount
    let avg = this.state.messagesPerMinuteAvg;
    let currentMin = this.state.messagesPerMinuteMin;
    let currentMax = this.state.messagesPerMinuteMax;
    let tempAvg = 0;

    count++;

    //Mean
    if(avg !== '-' && rate !== 0){
      const diff = (rate - avg) / count;
      tempAvg = Math.floor(avg + diff);
      tempObj['messagesPerMinuteAvg'] = tempAvg;
    }else if(rate !== 0){
      tempObj['messagesPerMinuteAvg'] = rate;
    }

    //Minimum
    if((rate < currentMin || currentMin === '-') && rate !== 0){
      tempObj['messagesPerMinuteMin'] = rate;
    }

    //Maximum
    if (rate >  currentMax || currentMax === '-' ){
      tempObj['messagesPerMinuteMax'] = rate;
    }

    tempObj['messageCount'] = count;
    tempObj['messagesPerMinute'] = rate;

    this.setState(tempObj);
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      rows,
      columns,
      sorting,
      pageSize,
      pageSizes,
      currentPage,
      grouping,
      selection,
      integratedSortingColumnExtensions,
      filters,
      messagesPerMinute,
      messagesPerMinuteMax,
      messagesPerMinuteMin,
      messagesPerMinuteAvg,
      alertCount,
      clientCount,
      ambrCount,
      xpoeCount,
      connectionStatus,
    } = this.state;

    return (
      <div>
        <BlockTop
          messagesPerMinute = {messagesPerMinute}
          messagesPerMinuteMax = {messagesPerMinuteMax}
          messagesPerMinuteMin = {messagesPerMinuteMin}
          messagesPerMinuteAvg = {messagesPerMinuteAvg}
          alertCount = {alertCount}
          clientCount = {clientCount}
          ambrCount = {ambrCount}
          xpoeCount = {xpoeCount}
          connectionStatus = {connectionStatus}
          handleStatusClick = {this.handleStatusClick}
        />
        <h2 className="courseReportTitle">Connected MQTT Devices</h2>
        <Paper style={{borderRadius: 16}}>
          <GridTable rows={rows} columns={columns}>

            <RowDetailState defaultExpandedRowIds={[]} />
            <SelectionState
              selection={selection}
              onSelectionChange={this.changeSelection}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <GroupingState
              grouping={grouping}
              onGroupingChange={this.changeGrouping}
            />
             <FilteringState
                defaultFilters={[]}
                filters={filters}
                onFiltersChange={this.changeFilters}
            />
            <IntegratedFiltering />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />

            <IntegratedGrouping />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedPaging />
            <DragDropProvider />
            <Table/>
            {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
            <TableSelection
              selectByRowClick
              highlightRow
              showSelectionColumn={false}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableColumnVisibility
                defaultHiddenColumnNames={this.getDefaultHiddenColumnNames()}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <TableGroupRow />
            <Toolbar />
            <GroupingPanel showGroupingControls />
            <ColumnChooser />
            <PagingPanel pageSizes={pageSizes} />
          </GridTable>
        </Paper>
        <Paper style={{borderRadius: 16, marginTop:20}}>
          <p className="">Click on a column name to sort by that column, shift click to sort by multiple. You can also group by multiple columns. Click the mqtt status icon to reconnect or pause</p>
        </Paper>
      </div>
    );
  }
}
export default AppTableGrid;