import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "material-ui/styles";
import Grid from "material-ui/Grid";
// import Paper from "material-ui/Paper";
// import Card, { CardActions, CardContent } from 'material-ui/Card';
import Card, {CardContent } from 'material-ui/Card';
import Typography from 'material-ui/Typography';
// import Button from 'material-ui/Button';


const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
    marginBottom: 30
  },
  paper: {
    height: 140,
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  grid: {
    padding: 0
  },
  card: {
    borderRadius:16
  },
  cardContent: {
    padding: '16px !important',
  },
  cardBody: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  cardIcon: {
    minHeight: 64,
    textAlign: 'center',
    color: '#FFA534',
  },
  cardNumber: {
    textAlign: 'right',
  },
  cardFooter: {
    fontSize: 14,
  },
  cardTicketOpen: {
    fontSize: 21,
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
  },
});
const MqttConnectionStatus = (props) => {
  switch(props.connectionStatus){
      case 'Connected':
          return(
              <i className='fal fa-check-circle fa-3x text-success' aria-hidden="true"></i>
          );
      case 'Connecting':
          return(
              <i className='fal fa-retweet fa-3x text-primary' aria-hidden="true"></i>
          );
      case 'Disconnected':
          return(
              <i className='fal fa-times-circle fa-3x text-error' aria-hidden="true"></i>
          );
      case 'Paused':
          return(
              <i className='fal fa-pause-circle fa-3x text-primary' aria-hidden="true"></i>
          );
      default:
          return(
              <i className='fa fa-question-sign fa-3x text-primary' aria-hidden="true"></i>
          );   
  }
}

class BlockTop extends React.Component {
  state = {
    spacing: "16",
  };

  handleChange = key => (event, value) => {
    this.setState({
      [key]: value
    });
  };

  render() {
    const { classes } = this.props;
    // const { spacing } = this.state;
    // const bull = <span className={classes.bullet}>•</span>;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.cardBody}>
                <div className="row">
                  <div className="col-5">
                    <div className={classes.cardIcon}>
                      <i className="fal fa-server fa-3x text-success" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={classes.cardNumber}>
                      <Typography component="p" className={classes.cardCategory}>Connected devices</Typography>
                      <h4 className={classes.cardTitle}>{this.props.clientCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <hr/>
                <div className="stats">
                  <span>AMBRS:  {this.props.ambrCount}</span> <span style={{marginLeft:15}}> X-PoE Switches: {this.props.xpoeCount}</span>
                  {/* <i className="fa fa-clock-o"></i> Just updated */}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.cardBody}>
                <div className="row">
                  <div className="col-5">
                    <div className={classes.cardIcon}>
                      <i className="fal fa-exclamation-triangle fa-3x text-error" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={classes.cardNumber}>
                      <Typography component="p" className={classes.cardCategory}>Alerts</Typography>
                      <h4 className={classes.cardTitle}>{this.props.alertCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <hr />
                <div className="stats">
                  -
                  {/* <i className="fa fa-clock-o"></i> Updated {this.props.updated} minutes ago  */}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.cardBody}>
                <div className="row">
                  <div className="col-5">
                    <div className={classes.cardIcon}>
                      
                      <i className="fal fa-heart-rate fa-3x text-primary" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={classes.cardNumber}>
                      <Typography component="p" className={classes.cardCategory}>Msgs Per Minute</Typography>
                      <h4 className={classes.cardTitle}>{this.props.messagesPerMinute}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <hr />
                <div className="stats">
                  Stats:
                  <i className="fal fa-arrow-to-top" style={{'marginLeft':'10px', 'marginRight':'10px'}}></i> 
                    {this.props.messagesPerMinuteMax}
                  <i className="fal fa-arrow-to-bottom"style={{'marginLeft':'10px', 'marginRight':'10px'}}></i> 
                    {this.props.messagesPerMinuteMin}
                  <i className="fal fa-tachometer-average"style={{'marginLeft':'10px', 'marginRight':'10px'}}></i> 
                    {this.props.messagesPerMinuteAvg}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.cardBody}>
                <div className="row">
                  <div className="col-5">
                    <div className={classes.cardIcon} style={{cursor:'pointer'}} onClick={this.props.handleStatusClick} >
                      <MqttConnectionStatus connectionStatus = {this.props.connectionStatus}/>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className={classes.cardNumber}>
                      <Typography component="p" className={classes.cardCategory}>MQTT Status</Typography>
                      <h4 className={classes.cardTitle}>{this.props.connectionStatus}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <hr />
                <div className="stats">
                  -
                  {/* <i className="fa fa-clock-o"></i> Just updated */}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

BlockTop.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BlockTop);
